// TODO rename/restructure this file, now that it can contain an exludes list
const SelectorKeys = [
  "cartDrawer",
  "checkoutButton",
  "discountMessageLastSibling",
  "lineItemContainer",
  "lineItemPerItemPrice",
  "lineItemTitle",
  "lineItemTotalPrice",
  "lineItemVariantTitle",
  "openCartButton",
  "subtotal"
];

const Selectors = {
  Boundless: {
    cartDrawer: "#CartDrawer",
    checkoutButton: ["#CartDrawer button[name='checkout']"],
    discountMessageLastSibling: "#CartDrawer .ajaxcart__row:last",
    lineItemContainer: "#CartDrawer .ajaxcart__row",
    lineItemTitle: ".ajaxcart__product-name",
    lineItemTotalPrice:
      ".grid .grid__item .grid .grid__item.one-half.text-right",
    lineItemVariantTitle: ".ajaxcart__product-meta",
    openCartButton: "header.site-header .cart-link",
    subtotal:
      "#CartDrawer .ajaxcart__subtotal .grid .grid__item.text-right span"
  },
  Brooklyn: {
    cartDrawer: "#CartDrawer",
    checkoutButton: [
      "#CartContainer > form > div.ajaxcart__footer.ajaxcart__footer--fixed > button"
    ],
    discountMessageLastSibling: "#CartContainer .ajaxcart__product:last",
    lineItemContainer: ".ajaxcart__row",
    lineItemTitle: ".ajaxcart__product-name",
    lineItemTotalPrice: ".ajaxcart__price",
    lineItemVariantTitle: ".ajaxcart__product-meta",
    openCartButton: ".js-drawer-open-button-right",
    subtotal:
      "#CartContainer > form > div.ajaxcart__footer.ajaxcart__footer--fixed > div > div.grid__item.one-third.text-right > p"
  },
  Debutify: {
    cartDrawer: "#CartDrawer",
    checkoutButton: ["#CartDrawer button[name='checkout']"],
    discountMessageLastSibling: "#CartDrawer .cart__total-price-info",
    lineItemContainer: "#CartDrawer .ajaxcart_row",
    lineItemTitle: ".cart__product-name a",
    lineItemTotalPrice: ".cart__price",
    lineItemVariantTitle: ".grid p.text-small",
    openCartButton: "header a.cart-link",
    subtotal:
      "#CartDrawer .cart__subtotal > .money, #CartDrawer button[name='checkout'] .cart-cost"
  },
  Impulse: {
    excludes: ["3.", "2.", "1."],
    cartDrawer: "#CartDrawer",
    checkoutButton: ["button.cart__checkout"],
    discountMessageLastSibling:
      ".drawer__footer > .cart__item-sub.cart__item-row",
    lineItemContainer: ".cart__item",
    lineItemTitle: ".cart__item-title a",
    lineItemPerItemPrice: ".cart__price",
    lineItemVariantTitle: ".cart__item--variants > div",
    openCartButton: ".js-drawer-open-cart",
    subtotal: ".cart__item-sub div[data-subtotal]"
  },
  Narrative: {
    cartDrawer: ".drawer",
    checkoutButton: ["button.cart-drawer__checkout"],
    discountMessageLastSibling: ".cart-drawer__subtotal",
    lineItemContainer: ".cart-drawer__item-content",
    lineItemTitle: ".cart-drawer__item-title",
    lineItemTotalPrice: ".cart-item__original-price.cart-item__price",
    lineItemVariantTitle: ".cart-drawer__item-subtitle",
    openCartButton: ".site-header__cart.ajax-cart__toggle",
    subtotal: ".cart-drawer__subtotal-number"
  },
  Prestige: {
    cartDrawer: "#sidebar-cart",
    checkoutButton: ["#sidebar-cart .Cart__Checkout.Button.Button--primary"],
    discountMessageLastSibling: ".Cart__Taxes",
    lineItemContainer: ".CartItemWrapper",
    lineItemTitle: ".CartItem__Title > a",
    lineItemTotalPrice: ".CartItem__Price.Price",
    lineItemVariantTitle: ".CartItem__Variant",
    openCartButton: "header a[data-action='open-drawer']",
    subtotal: ".Cart__Checkout.Button.Button--primary > span:nth-child(3)"
  },
  Supply: {
    cartDrawer: "#ajaxifyCart",
    checkoutButton: ["#ajaxifyCart button"],
    discountMessageLastSibling: "#ajaxifyCart .cart-subtotal",
    lineItemContainer: ".cart-row",
    lineItemTitle: ".grid-item.two-thirds.large--three-quarters > a",
    lineItemTotalPrice: ".grid-item.one-half.medium-down--text-left.text-right",
    lineItemVariantTitle: ".grid-item.two-thirds.large--three-quarters > small",
    openCartButton: ".header-cart-btn.cart-toggle",
    subtotal: ".cart-subtotal > span > span > small"
  },
  Motion: {
    cartDrawer: "#CartDrawer",
    checkoutButton: "button.btn.cart__checkout",
    discountMessageLastSibling:
      ".drawer__footer > .cart__item-sub.cart__item-row",
    lineItemContainer: ".cart__item",
    lineItemTitle: ".cart__product-name",
    lineItemPerItemPrice: ".cart__price",
    lineItemVariantTitle: ".cart__variant-meta",
    openCartButton: ".site-nav__icons a[href='/cart']",
    subtotal: "#CartDrawer [data-subtotal]"
  }
};

module.exports = { SelectorKeys, Selectors };
