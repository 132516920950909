const Styles = {
  Boundless: `
    #CartDrawer button[name='checkout'] {
      transform: translate(0, -24px);
    }
  `,
  Brooklyn: `
    #CartContainer .ajaxcart__subtotal + #saso-ajax-discounted-price {
      margin-top: -15px;
    }
  `,
  Debutify: `
    #CartDrawer .saso-cart-item-discount-notes,
    #CartDrawer .saso-cart-item-upsell-notes {
      white-space: initial;
    }
    #CartDrawer button .saso-cart-total {
      display: inherit;
    }
  `,
  Impulse: `
    .saso-bundle-popup .saso-products-container .saso-plus-container .saso-plus-container-inner,
    .saso-bundle-popup .saso-products-container .saso-product-container .saso-image .saso-quantity-container {
      z-index: 25;
    }`,
  Motion: `
    .saso-bundle-popup .saso-products-container .saso-plus-container .saso-plus-container-inner,
    .saso-bundle-popup .saso-products-container .saso-product-container .saso-image .saso-quantity-container {
      z-index: 25; 
    }`,
  Prestige: `
    .CartItem .CartItem__Info .CartItem__Title .saso-ajax-line-item-notes {
      white-space: normal;
    }
  `
};

module.exports = Styles;
