import { Selectors } from "types/constants/selectors";

import applyCustomStyles from "./apply-custom-styles";
import compileThemeSelectors from "./helpers/compile-theme-selectors";

// TODO Do we need author as well as the theme name?
// TODO do we need to normalize the themeName?
/*
  @param {name: String, version: Number} theme information object
  @param String override selectors json config URL
*/
export async function usoAjaxCartBootstrap(themeInfo, assetUrl) {
  applyCustomStyles(themeInfo.name);

  let overrideSelectors = compileThemeSelectors(themeInfo.name, Selectors);

  if (assetUrl) {
    try {
      overrideSelectors = await fetch(assetUrl).then(data => data.json());
    } catch {
      // use default
    }
  }

  window.saso_ajax_config = window.saso_ajax_config || {};
  window.saso_ajax_config.selectors = overrideSelectors;
  window.saso_ajax_config.themeInfo = themeInfo;
}

window.usoAjaxCartBootstrap = usoAjaxCartBootstrap;
