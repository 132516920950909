import jQuery from "jquery";

import * as Styles from "types/constants/styles";

const applyCustomStyles = themeName => {
  const customStyles = Styles[themeName];

  if (!customStyles) {
    return;
  }

  const styleTag = jQuery(`<style>${customStyles}</style>`);

  jQuery("html > head").append(styleTag);
};

export default applyCustomStyles;
