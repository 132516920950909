import { SelectorKeys } from "types/constants/selectors";

const META_KEYS = new Set("compile", "excludes");

const compileThemeSelectors = (themeName, themeSelectors) => {
  const selectorsByVersion = themeSelectors[themeName];

  if (!selectorsByVersion || !selectorsByVersion.compile) {
    return selectorsByVersion;
  }

  return Object.keys(selectorsByVersion).reduce(
    (combinedSelectors, version) => {
      if (META_KEYS.has(version)) {
        return combinedSelectors;
      }

      for (const selectorKey of SelectorKeys) {
        const selectors = selectorsByVersion[version];
        const selectorValue = selectors[selectorKey];

        if (!combinedSelectors[selectorKey] && selectors[selectorKey]) {
          combinedSelectors[selectorKey] = selectorValue;
          continue;
        }

        if (typeof selectorValue === "string") {
          combinedSelectors[selectorKey] += `, ${selectorValue}`;
          continue;
        }

        if (Array.isArray(selectorValue)) {
          combinedSelectors[selectorKey] =
            combinedSelectors[selectorKey].concat(selectorValue);
          continue;
        }
      }

      return combinedSelectors;
    },
    {}
  );
};

export default compileThemeSelectors;
